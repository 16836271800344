
        <template>
          <div class="vc-snippet-doc">
            <h2>Pan</h2>
<p>云盘：上传文件保存到云盘中，获取云盘中文件的连接</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">eui-button</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> @<span class="hljs-attr">click</span>=<span class="hljs-string">"visibleFile = true"</span>&gt;</span>打开云盘<span class="hljs-tag">&lt;/<span class="hljs-name">eui-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-pan</span> <span class="hljs-attr">:visible.sync</span>=<span class="hljs-string">"visibleFile"</span> @<span class="hljs-attr">save</span>=<span class="hljs-string">"save"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-pan</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">visibleFile</span>: <span class="hljs-literal">false</span>
        }
    },
    <span class="hljs-attr">methods</span>: {
        save(file) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'file:'</span>, file)
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>visible</td>
<td>是否显示，支持.sync</td>
<td>Boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>fileType</td>
<td>展示的文件类型</td>
<td>Array</td>
<td><code>[&quot;img&quot;, &quot;video&quot;, &quot;file&quot;]</code></td>
<td><code>[&quot;img&quot;, &quot;video&quot;, &quot;file&quot;]</code></td>
</tr>
<tr>
<td>token</td>
<td>token</td>
<td>String</td>
<td>—</td>
<td>''</td>
</tr>
<tr>
<td>action</td>
<td>上传文件的地址</td>
<td>String</td>
<td>—</td>
<td>''</td>
</tr>
<tr>
<td>save</td>
<td>保存文件的方法，返回选择的文件对象</td>
<td>Function</td>
<td>—</td>
<td>-</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('eui-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visibleFile = true}}},[_vm._v("打开云盘")]),_vm._v(" "),_c('ixu-pan',{attrs:{"visible":_vm.visibleFile},on:{"update:visible":function($event){_vm.visibleFile=$event},"save":_vm.save}})],1)}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            visibleFile: false
        }
    },
    methods: {
        save(file) {
            console.log('file:', file)
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>